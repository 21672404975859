<script setup>
  import VLazyImage from "v-lazy-image";
</script>
<template>
  <div v-if="loaded">

     <div class="container-fluid ">
        <img class="img-fluid" :src="imgUrl+'journal/'+currentData.jData.id" />

     </div>
    <div class="container">
  
        <div class="row p-3 m-3">
        <div class="col-lg-4 col-md-6 col-sm-6 mt-80">
            <div class="card bg-white d-flex align-items-center justify-content-center ">
                <div class="w-100 text-center"><unicon  name="user-circle" fill="royalblue"></unicon></div>
                <div class="text-center ">
                    <h4 class="name">{{rsvp.contactName}}</h4>
                    <h5 class="job">{{rsvp.contactNumber}}</h5>
                    <button class="btn btn-primary mb-2" @click="showModal()"> Response RSVP</button>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mt-80">
            <div class="card bg-white align-items-center justify-content-center">
                <div class="w-100 text-center"><unicon  name="map-marker" fill="royalblue"></unicon></div>
                <div class="text-center ">
                    <p class="name">{{address.houseUnitNo}}</p>
                    <p class="job">{{address.address}} </p>
                    <p class="job">{{address.city}} {{address.state}}  {{address.postcode}}</p>
                    <button class="btn btn-success mb-2" @click="openGM()">Go to Location</button>
              
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 mt-80">
            <div class="card bg-white d-flex align-items-center justify-content-center">
                <div class="w-100 text-center"><unicon  name="calendar-alt" fill="royalblue"></unicon></div>
                <div class="text-center ">
                    <p class="name">{{config.eventDate}}</p>
                    <p class="job">{{config.startTime}} - {{config.endTime}}</p>
<p>
               <unicon  name="phone" fill="royalblue">    </unicon> <a :href="'tel:'+config.supportContact">{{config.supportContact}}</a></p><br/>
                   
                </div>
            </div>
        </div>
       
    </div>
</div>
  <section class="container row" v-if="loaded">
      <Photo
        v-for="product in products"
        :key="product.id"  @click="showModal(imgUrl+'documents/'+product.id)"
        :product="product" :journal="currentData.jData" 
      />
    </section>
       <div ref="image_modal" id="image_modal" v-if ="modalShow" v-cloak  class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
     
            <div class="modal-body">
                     <button type="button" class="close" aria-label="Close" @click="hideModal">
  <span aria-hidden="true">&times;</span>
</button>
<img class="image" v-if="selectedImgPath!=''" :src='selectedImgPath'/>
<div v-if="selectedImgPath==''">
                <div class="form-group" v-if="config.attendeeClassification.indexOf('pacx')>-1">
        <label for="title">Number of Person</label>
        <input
          type="number"
          class="form-control"
          id="pacx"
          v-model="rsvp.pacx"
          name="pacx"
             max="99"
          min="0"
          @change="updateTotal"
          maxlength="2"
        />
      </div>

      <div class="form-group" v-if="config.attendeeClassification.indexOf('adult')>-1">
        <label for="description">Number of Adult</label>
        <input
          class="form-control"
          id="adult"
          type="number"
          v-model="rsvp.adult"
          name="adult"
          max="99"
          min="0"
          maxlength="2"
           @change="updateTotal"
        />
      </div>
       <div class="form-group" v-if="config.attendeeClassification.indexOf('man')>-1">
        <label for="description">Number of Man</label>
        <input
          class="form-control"
          id="man"
         type="number"
          v-model="rsvp.man"
          name="man"
          max="99"
          min="0"
           maxlength="2"
            @change="updateTotal"
        />
      </div>
       <div class="form-group" v-if="config.attendeeClassification.indexOf('woman')>-1">
        <label for="description">Number of Woman</label>
        <input
          class="form-control"
          id="woman"
          type="number"
          v-model="rsvp.woman"
          name="woman"
          max="99"
          min="0"
           maxlength="2"
            @change="updateTotal"
        />
      </div>
       <div class="form-group" v-if="config.attendeeClassification.indexOf('child')>-1">
        <label for="description">Number of Children</label>
        <input
          class="form-control"
          id="child"
          max="99"
          min="0"
          type="number"
          v-model="rsvp.child"
          name="child"
           maxlength="2"
            @change="updateTotal"
        />
      </div>
     
        <table class="table  table-striped" v-if="classes">
    <thead>
        <th scope="col">Particular</th>
        <th scope="col">Number</th>
        <th scope="col" v-if="config.billingType>0">Cost/Pacx</th>
        <th scope="col"  v-if="config.billingType>0">Sub-Total</th>
       
    </thead>
    <tbody>
        <tr v-for="(cls,index) in classes" :key="index">
          <td>{{ cls.name }}</td>
          <td>{{ cls.count }}</td>
          <td  v-if="config.billingType>0">{{ cls.rate }}</td>
          
          <td  v-if="config.billingType>0">{{cls.rate * cls.count }}</td>
        </tr>
        <tr>
        <th>Total</th>
        <th>{{total}}</th>
        <th scope="col" v-if="config.billingType>0">..</th>
        <th scope="col"  v-if="config.billingType>0">{{totalCost}}</th>
        </tr>
    </tbody>
</table>
     <div v-if="rsvpupdated" class="row ">
       <div class="alert alert-danger" v-if="error">{{error}}</div>
      <button @click="update(1)" class="btn btn-success mr-5" v-if="rsvp.confirmation==0">RSVP - Yes</button>
      <button @click="update(3)" class="btn btn-danger ml-5" v-if="rsvp.confirmation==0">RSVP - No</button>
     </div>
      <div v-else>
         <div class="spinner-border spinner" role="status">
            <span class="sr-only">updating...</span>
        </div>
     </div>
<div class="alert alert-warning" v-if="rsvp.confirmation>0">You have update your status once . If you want to change the status please use  MyPaaa App </div>
            </div>
          </div>
      </div>
   </div>
   </div>
   <Footer />

     <transition name="fade">
    <div id="pagetop" class="fixed right-0 bottom-0" v-show="scY>300" @click="toTop">
     <svg width="48px" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6907 4.8866C21.5876 3.54639 20.5567 2.41237 19.1134 2.30928C17.0515 2.10309 14.1649 2 12 2C9.83505 2 6.94845 2.10309 4.8866 2.30928C4.16495 2.30928 3.54639 2.61856 3.13402 3.13402C2.72165 3.64948 2.41237 4.16495 2.30928 4.8866C2.10309 6.94845 2 9.83505 2 12C2 14.1649 2.20619 17.0515 2.30928 19.1134C2.41237 20.4536 3.4433 21.5876 4.8866 21.6907C6.94845 21.8969 9.83505 22 12 22C14.1649 22 17.0515 21.7938 19.1134 21.6907C20.4536 21.5876 21.5876 20.5567 21.6907 19.1134C21.8969 17.0515 22 14.1649 22 12C22 9.83505 21.8969 6.94845 21.6907 4.8866ZM16.6392 13.5464C16.433 13.6495 16.3299 13.7526 16.1237 13.7526C15.9175 13.7526 15.7113 13.6495 15.6082 13.5464L12.2062 10.1443C12.1031 10.0412 11.8969 10.0412 11.7938 10.1443L8.39175 13.5464C8.08247 13.8557 7.56701 13.8557 7.25773 13.5464C6.94845 13.2371 6.94845 12.7216 7.25773 12.4124L10.6598 9.01031C11.3814 8.28866 12.5155 8.28866 13.2371 9.01031L16.6392 12.4124C16.9485 12.8247 16.9485 13.2371 16.6392 13.5464Z" fill="#030D45"/>
</svg>
    </div>
  </transition>
  </div>

  <div v-else>
    <br />
   <div class="spinner-border spinner" role="status">
  <span class="sr-only">Loading...</span>
</div>
  </div>
</template>

<script>
import MyPaaaDataService from "../services/MyPaaaDataService";
import Photo from './utils/Photo';
import Footer from './utils/Footer';

export default {
  
  name: "DetailsPage",
  inject: ['imgUrl'],
  components: {
    Photo,
    Footer
  },
  data() {
    return {
      currentData: null,
      message: '',
      loaded : false,
      subData:null,
      rsvp:null,
      products:null,
      scTimer: 0,
      scY: 0,
      modalShow: false,
      config:null,
      address:null,
      selectedImgPath: '',
      selectedId:'',
      classes:null,
      total:0,
      totalCost:0,
      rsvpupdated:true,
      error:'',
      businessEntry:{
      "businessAccountType": 1,
      "invoiceId": "",
      "contactNumber": "",
      "amount": 0,
      "repeatType": 0,
      "insertType": 0,
      "entryRole": 1,
      "particular": "",
      "journalId": "",
      "colOther": ""
}
    };
  },
  methods: {
    updateTotal(e){
      var data=[];
      this.total=0;
       this.totalCost=0;
      if(this.config.attendeeClassification.indexOf('pacx')>-1){
        data.push({
          name:'Pacx',
          count:this.rsvp.pacx,
          rate:this.config.pacx
        });
        this.total += parseInt(this.rsvp.pacx);
        this.totalCost += parseInt(this.rsvp.pacx)*this.config.pacx ;
      }
        if(this.config.attendeeClassification.indexOf('adult')>-1){
        data.push({
          name:'Adult',
          count:this.rsvp.adult,
          rate:this.config.adult
        });
        this.total += parseInt(this.rsvp.adult);
        this.totalCost += parseInt(this.rsvp.adult)*this.config.adult ;
        }
        if(this.config.attendeeClassification.indexOf('man')>-1){
        data.push({
          name:'Man',
          count:this.rsvp.man,
          rate:this.config.man
        });
        this.total += parseInt(this.rsvp.man);
        this.totalCost += parseInt(this.rsvp.man)*this.config.man ;
        }
        if(this.config.attendeeClassification.indexOf('woman')>-1){
        data.push({
          name:'Woman',
          count:this.rsvp.woman,
          rate:this.config.woman
        });
        this.total += parseInt(this.rsvp.woman);
        this.totalCost += parseInt(this.rsvp.woman)*this.config.woman ;
        }
        if(this.config.attendeeClassification.indexOf('child')>-1){
        data.push({
          name:'Child',
          count:this.rsvp.child,
          rate:this.config.child
        });
        this.total += parseInt(this.rsvp.child);
        this.totalCost += parseInt(this.rsvp.child)*this.config.child ;
        
      }
      this.classes = data;
    },
    openGM(){
    var urlSuffix = this.currentData.jData.location.coordinates[1]+","+this.currentData.jData.location.coordinates[0]
 window.open(
          "https://maps.google.com/?q=" + urlSuffix+"&name="+this.address.houseUnitNo,
          "_blank"
        )
    },
    filterTop(datas){
     const key = 'category';
      const arrayUniqueByKey = [...new Map(datas.map(item =>
  [item[key], item])).values()];
return arrayUniqueByKey;
    },
     filterCategory(item){
       let category= item.category;
       this.selectedId = item.id
     //const key = 'category';
      const arrayUniqueByKey = this.subData.filter(item => item.category === category); 
       this.products =  arrayUniqueByKey;
    },
    get(id) {
      MyPaaaDataService.getEvent(id)
        .then(response => {
          this.currentData = response.data;
          this.subData = this.currentData.subData;
          this.rsvp = this.currentData.category ;
          this.products =  this.subData ;
          this.emitter.emit('updateTitle', this.currentData.jData.title);
          this.address = JSON.parse(this.currentData.jData.address);
          this.config = JSON.parse(this.currentData.jData.config);
          this.loaded = true;
          updateTotal();
        })
        .catch(e => {
          console.log(e);
           this.loaded = true;
        });
    },
    update(status) {
      this.error ='';
      var dialog =status==1?'Thank you :Are your sure about your RSVP Status with number?':'Your are declining the RSVP, are you sure?'
      var id=this.$route.params.id;
      if(confirm(dialog)){
       this.rsvpupdated = false;
        var data={};
       if(status==1){
       this.businessEntry.invoiceId = this.rsvp.contactNumber;
       this.businessEntry.contactNumber = this.rsvp.contactNumber;
       this.businessEntry.particular = this.rsvp.contactName;
       this.businessEntry.journalId = this.rsvp.journalId;
       this.businessEntry.amount = -this.totalCost;
        this.businessEntry.colOther = this.rsvp.contactNumber;
        data={
            entries: this.config.billingType>0 ? [ this.businessEntry]:[],
            "pacx": this.rsvp.pacx,
            "adult": this.rsvp.adult,
            "man": this.rsvp.man,
            "woman": this.rsvp.woman,
            "child": this.rsvp.child,
            "total": this.config.billingType>0 ? this.totalCost:0,
            "paymentStatus": 0,
            "confirmation": status,
       }
       }else{
           data={
             entries:[],
            "confirmation": status,
       }
       }
       }
      MyPaaaDataService.updateEvent(id,data)
        .then(response => {
          this.rsvpupdated = true;
         // updateTotal();
        })
        .catch(e => {
         // console.log("VVVVVVVV");
          //console.log(e.Error);
           this.rsvpupdated = true;
           //alert( e.Error);
            this.error = 'Spmething went wrong';
        });
    },
     showModal(imgPath) {
       if(imgPath==undefined){
 this.selectedImgPath = ''
       }else{
 this.selectedImgPath = imgPath
       }
     
      this.modalShow = true
    },
    hideModal(){
    this.modalShow = false
    },
  handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          console.log(this.scY);
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 100);
      },
      toTop: function () {
       // alert('aaa')
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
  
  },
  mounted() {
    this.message = '';
     this.loaded = false ;
    this.get(this.$route.params.id);
     window.addEventListener('scroll', this.handleScroll);
  }
};

</script>

<style>
.modal{
  display: inline-block !important;
}
.modal-dialog {
  max-width: 97% !important;
}
.modal > .modal-dialog > .modal-content > .modal-header {
  background-color: #0e0505b7 !important;
  color: white;
}
.edit-form {
  max-width: 300px;
  margin: auto;
}
.spinner {
  border: 1px solid;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  margin: auto;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 50 50'%3E%3Cpath d='M28.43 6.378C18.27 4.586 8.58 11.37 6.788 21.533c-1.791 10.161 4.994 19.851 15.155 21.643l.707-4.006C14.7 37.768 9.392 30.189 10.794 22.24c1.401-7.95 8.981-13.258 16.93-11.856l.707-4.006z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E") center / contain no-repeat;
}
.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
 
}
 .scrolling-wrapper .card {
    display: inline-block;
  }
.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  
  
}
.scrolling-wrapper-flexbox .card {
    flex: 0 0 auto;
    margin-right: 3px;
  }

  


.job {
    color: #fa2525;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px
}

.container .card .icons .icon {
    font-size: 14px;
    width: 30px;
    height: 30px;
    color: white;
    background-color: #fa2525;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.dis {
    color: #7e7c7c;
    line-height: 2
}

.container .card .icons .icon:hover {
    background-color: rgb(235, 123, 103)
}

.mt-80 {
    margin-top: 80px
}
html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #faf8f8;
}
::-webkit-scrollbar {
    display: none;
  }
   .products {
    display: flex;
    flex-direction: column ;
    max-width: 100%;
    padding: 25px;
    margin: 0 auto;
  }
  #btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}
#pagetop{
  position:fixed;
  bottom: 5px;
  right: 5px;
}
.active {
  border: 5px solid #f7d306f5;
}
.not-active {
  border: 1px solid #fafbfcf5;
}

</style>
